const state = {
    visitedTags: [],
    cachedTags: []
}

const mutations = {
    ADD_VISITED_TAG: (state, tag) => {
        if (state.visitedTags.some(v => v.path === tag.path)) return
        state.visitedTags.push(
            Object.assign({}, tag, {
                title: tag.meta.title || 'no-name'
            })
        )
    },
    ADD_CACHED_TAG: (state, tag) => {
        if (state.cachedTags.includes(tag.name)) return
        if (!tag.meta.noCache) {
            state.cachedTags.push(tag.name)
        }
    },

    DEL_VISITED_TAG: (state, tag) => {
        for (const [i, v] of state.visitedTags.entries()) {
            if (v.path === tag.path) {
                state.visitedTags.splice(i, 1)
                break
            }
        }
    },
    DEL_CACHED_TAG: (state, tag) => {
        for (const i of state.cachedTags) {
            if (i === tag.name) {
                const index = state.cachedTags.indexOf(i)
                state.cachedTags.splice(index, 1)
                break
            }
        }
    },

    DEL_OTHERS_VISITED_TAGS: (state, tag) => {
        state.visitedTags = state.visitedTags.filter(v => {
            return v.meta.fixed || v.path === tag.path
        })
    },
    DEL_OTHERS_CACHED_TAGS: (state, tag) => {
        for (const i of state.cachedTags) {
            if (i === tag.name) {
                const index = state.cachedTags.indexOf(i)
                state.cachedTags = state.cachedTags.slice(index, index + 1)
                break
            }
        }
    },

    DEL_ALL_VISITED_TAGS: state => {
        const fixedTags = state.visitedTags.filter(tag => tag.meta.fixed)
        state.visitedTags = fixedTags
    },
    DEL_ALL_CACHED_TAGS: state => {
        state.cachedTags = []
    },

    DEL_LEFT_VISITED_TAGS: (state, tag) => {
        var fixedTags = []
        for (const [i, v] of state.visitedTags.entries()) {
            if (v.path === tag.path) {
                state.visitedTags.splice(0, i)
                break
            }
            if (v.meta && v.meta.fixed) fixedTags.push(v)
        }
        state.visitedTags = [...fixedTags, ...state.visitedTags]
    },
    DEL_LEFT_CACHED_TAGS: (state, tag) => {
        for (const i of state.cachedTags) {
            if (i === tag.name) {
                const index = state.cachedTags.indexOf(i)
                state.cachedTags = state.cachedTags.slice(0, index)
                break
            }
        }
    },

    DEL_RIGHT_VISITED_TAGS: (state, tag) => {
        var fixedTags = [],
            tags = state.visitedTags.reverse()
        for (const [i, v] of tags.entries()) {
            if (v.path === tag.path) {
                tags.splice(0, i)
                break
            }
            if (v.meta && v.meta.fixed) fixedTags.push(v)
        }
        state.visitedTags = [...tags.reverse(), ...fixedTags.reverse()]
    },
    DEL_RIGHT_CACHED_TAGS: (state, tag) => {
        let index = state.cachedTags.indexOf(tag.name)
        if (index < 0) index = 0
        state.cachedTags = state.cachedTags.slice(index)
    },

    UPDATE_VISITED_TAG: (state, tag) => {
        for (let v of state.visitedTags) {
            if (v.path === tag.path) {
                v = Object.assign(v, tag)
                break
            }
        }
    }
}

const actions = {
    addTag({ dispatch }, tag) {
        dispatch('addVisitedTag', tag)
        dispatch('addCachedTag', tag)
    },
    addVisitedTag({ commit }, tag) {
        commit('ADD_VISITED_TAG', tag)
    },
    addCachedTag({ commit }, tag) {
        commit('ADD_CACHED_TAG', tag)
    },

    delTag({ dispatch, state }, tag) {
        return new Promise(resolve => {
            dispatch('delVisitedTag', tag)
            dispatch('delCachedTag', tag)
            resolve({
                visitedTags: [...state.visitedTags],
                cachedTags: [...state.cachedTags]
            })
        })
    },
    delVisitedTag({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_VISITED_TAG', tag)
            resolve([...state.visitedTags])
        })
    },
    delCachedTag({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_CACHED_TAG', tag)
            resolve([...state.cachedTags])
        })
    },

    delOthersTags({ dispatch, state }, tag) {
        return new Promise(resolve => {
            dispatch('delOthersVisitedTags', tag)
            dispatch('delOthersCachedTags', tag)
            resolve({
                visitedTags: [...state.visitedTags],
                cachedTags: [...state.cachedTags]
            })
        })
    },
    delOthersVisitedTags({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_OTHERS_VISITED_TAGS', tag)
            resolve([...state.visitedTags])
        })
    },
    delOthersCachedTags({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_OTHERS_CACHED_TAGS', tag)
            resolve([...state.cachedTags])
        })
    },

    delAllTags({ dispatch, state }, tag) {
        return new Promise(resolve => {
            dispatch('delAllVisitedTags', tag)
            dispatch('delAllCachedTags', tag)
            resolve({
                visitedTags: [...state.visitedTags],
                cachedTags: [...state.cachedTags]
            })
        })
    },
    delAllVisitedTags({ commit, state }) {
        return new Promise(resolve => {
            commit('DEL_ALL_VISITED_TAGS')
            resolve([...state.visitedTags])
        })
    },
    delAllCachedTags({ commit, state }) {
        return new Promise(resolve => {
            commit('DEL_ALL_CACHED_TAGS')
            resolve([...state.cachedTags])
        })
    },

    updateVisitedTag({ commit }, tag) {
        commit('UPDATE_VISITED_TAG', tag)
    },

    delLeftTags({ dispatch, state }, tag) {
        return new Promise(resolve => {
            dispatch('delLeftVisitedTags', tag)
            dispatch('delLeftCachedTags', tag)
            resolve({
                visitedTags: [...state.visitedTags],
                cachedTags: [...state.cachedTags]
            })
        })
    },
    delLeftVisitedTags({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_LEFT_VISITED_TAGS', tag)
            resolve([...state.visitedTags])
        })
    },
    delLeftCachedTags({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_LEFT_CACHED_TAGS', tag)
            resolve([...state.cachedTags])
        })
    },

    delRightTags({ dispatch, state }, tag) {
        return new Promise(resolve => {
            dispatch('delRightVisitedTags', tag)
            dispatch('delRightCachedTags', tag)
            resolve({
                visitedTags: [...state.visitedTags],
                cachedTags: [...state.cachedTags]
            })
        })
    },
    delRightVisitedTags({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_RIGHT_VISITED_TAGS', tag)
            resolve([...state.visitedTags])
        })
    },
    delRightCachedTags({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_RIGHT_CACHED_TAGS', tag)
            resolve([...state.cachedTags])
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
