import defaultSettings from '@/settings'

const title = defaultSettings.title || 'WeiShop Admin'

export function titleFormatter(pageTitle) {
    if (pageTitle) {
        return `${pageTitle} - ${title}`
    }
    return `${title}`
}

export function isWhiteList(path) {
    return defaultSettings.whiteList.indexOf(path) !== -1
}

export function getCopyright() {
    let year = new Date().getFullYear(),
        years = ''
    if (year > defaultSettings.beginYear)
        years = `${defaultSettings.beginYear}-${year}`
    else years = `${year}`
    return `Copyright © ${years} ${defaultSettings.company} 版权所有`
}
