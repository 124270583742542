import { login, logout, verifyToken } from '@/apis/bases'
import { parseTime } from '@/utils/utils.formatter'

const state = {
    token: sessionStorage.getItem('X-Token') || '',
    code: localStorage.getItem('USER_CODE') || 'Guest',
    name: sessionStorage.getItem('USER_NAME') || '游客',
    avatar:
        sessionStorage.getItem('USER_AVATAR') ||
        'https://weishop.api.sungmon.com/images/app-avatar.png',
    gender: 1,
    loginTime: sessionStorage.getItem('USER_LOGIN_TIME') || '',
    lastLoginTime: sessionStorage.getItem('USER_LAST_LOGIN_TIME') || '',
    saveCode: sessionStorage.getItem('USER_SAVE_CODE') || false
}

function setLocalStorage(key, value) {
    if (value === null) localStorage.removeItem(key)
    else localStorage.setItem(key, value)
}
function setSessionStorage(key, value) {
    if (value === null) sessionStorage.removeItem(key)
    else sessionStorage.setItem(key, value)
}
const mutations = {
    SET_TOKEN: (state, value) => {
        setLocalStorage('X-TOKEN', value)
        state.token = value
    },
    SET_CODE: (state, value) => {
        setLocalStorage('USER_CODE', value)
        state.code = value
    },
    SET_NAME: (state, value) => {
        setSessionStorage('USER_NAME', value)
        state.name = value
    },
    SET_AVATAR: (state, value) => {
        setSessionStorage('USER_AVATAR', value)
        state.avatar = value
    },
    SET_GENDER: (state, value) => {
        setSessionStorage('USER_GENDER', value)
        state.gender = value
    },
    SET_LOGIN_TIME: (state, value) => {
        setSessionStorage('USER_LOGIN_TIME', value)
        state.loginTime = value
    },
    SET_LAST_LOGIN_TIME: (state, value) => {
        setSessionStorage('USER_LAST_LOGIN_TIME', value)
        state.lastLoginTime = value
    },
    SET_SAVE_CODE: (state, value) => {
        setLocalStorage('USER_SAVE_CODE', value)
        state.saveCode = value
    }
}

const actions = {
    login({ commit }, userInfo) {
        const { userCode, password, saveCode } = userInfo
        commit('SET_SAVE_CODE', saveCode)
        return new Promise((resolve, reject) => {
            login({ userCode: userCode.trim(), password: password })
                .then(response => {
                    const { token, user } = response
                    console.log(user)
                    commit('SET_TOKEN', token)
                    commit('SET_CODE', user.userCode)
                    commit('SET_NAME', user.userName)
                    commit('SET_AVATAR', user.avatar)
                    commit('SET_GENDER', user.gender)
                    commit('SET_LOGIN_TIME', parseTime(new Date()))
                    commit('SET_LAST_LOGIN_TIME', user.lastLoginTime)
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout(state.token)
                .then(() => {
                    commit('SET_TOKEN', null)
                    //removeToken()
                    //resetRouter()
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    resetToken({ commit }) {
        return new Promise(resolve => {
            // commit("SET_TOKEN", "")
            // removeToken()
            // resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
