<template>
    <div class="floatball">
        <transition enter-active-class="animated bounceInRight"
                    leave-active-class="animated bounceOutLeft">
            <div v-show="isActive"
                 class="floatball-panel"
                 @click="$_ToggleActive">
                <div class="floatball-container">
                    <slot/>
                </div>
            </div>
        </transition>
        <transition enter-active-class="animated rotateIn"
                    leave-active-class="animated rotateOut">
            <div v-show="!isActive"
                 ref="ball"
                 class="floatball-handle"
                 @mousedown.stop="$_DragBall"
                 @click.stop="$_ToggleActive">
                <slot name="icon" />
            </div>
        </transition>
    </div>
</template>

<script>
    import Cookies from 'vue-cookies'

    export default {
        name: "FloatBall",
        data () {
            return {
                isActive: false,
                isDrag: false
            }
        },
        mounted () {
            setTimeout(() => {
                let ball = this.$refs.ball,
                    maxLeft = document.documentElement.clientWidth - 36,
                    maxTop = document.documentElement.clientHeight / 2 - 18,
                    left = Cookies.get("floatball_X"),
                    top = Cookies.get("floatball_Y");
                if (left <= 0 || left > maxLeft) left = maxLeft;
                if (top <= 0 || top > maxTop) top = maxTop;
                console.log(left, top);
                //移动当前元素
                ball.style.left = left + 'px';
                ball.style.top = top + 'px';
            }, 200);
        },
        methods: {
            $_ToggleActive () {
                if (!this.isDrag)
                    this.isActive = !this.isActive;
            },
            $_DragBall (e) {
                let ball = this.$refs.ball,
                    disX = e.clientX - ball.offsetLeft,
                    disY = e.clientY - ball.offsetTop,
                    left = 0,
                    top = 0;
                document.onmousemove = (e) => {
                    left = e.clientX - disX;
                    top = e.clientY - disY;
                    //移动当前元素
                    ball.style.left = left + 'px';
                    ball.style.top = top + 'px';
                    setTimeout(() => { this.isDrag = true; }, 100);
                };
                document.onmouseup = (e) => {
                    Cookies.set("floatball_X", left);
                    Cookies.set("floatball_Y", top);
                    document.onmousemove = null;
                    document.onmouseup = null;
                    setTimeout(() => { this.isDrag = false; }, 100);
                };
            }
        }
    }
</script>

<style lang="scss" scoped>
    .floatball {
    	-moz-user-select: none;
    	-webkit-user-select: none;
    	-ms-user-select: none;
    	-khtml-user-select: none;
    	position: absolute;
    	top: 0;
    	left: 0;

    	.floatball-handle {
    		position: fixed;
    		width: 36px;
    		height: 36px;
    		box-sizing: border-box;
    		background: rgba(0, 0, 0, 0.4);
    		border: 5px solid rgba(255, 255, 255, 0.5);
    		border-radius: 50%;
    		z-index: 1000000;
    		top: 100px;
    		right: 5px;
    		overflow: hidden;
    		text-align: center;
    		display: flex;
    		align-items: center;
    		justify-content: center;
    	}
    	.floatball-panel {
    		position: fixed;
    		top: 0;
    		bottom: 0;
    		left: 0;
    		right: 0;
    		background: rgba(0, 0, 0, 0.1);
    		z-index: 1000000;
    		text-align: center;
    		display: flex;
    		align-items: center;
    		justify-content: center;
    	}
    	.floatball-container {
    		text-align: left;
    		background: rgba(255, 255, 255, 1);
    		box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    		padding: 20px;
    		border-radius: 15px;
    		border: 1px solid #eee;
    		min-width: 100px;
    		min-height: 100px;
    		text-align: center;
    		display: flex;
    		align-items: center;
    		justify-content: center;
    	}
    	@media screen and (max-width: 300px) {
    	}
    }
</style>

