import Layout from "@/layout";

const functionsRouter = {
	path: "/functions",
	component: Layout,
	redirect: "/functions/list",
	name: "Functions",
	children: [
		{
			path: "list",
			component: () => import("@/views/Functions/function_list"),
			name: "FunctionList",
			meta: {
				title: "功能列表",
				fixed: false
			}
		},
		{
			path: "create",
			component: () => import("@/views/Functions/function_create"),
			name: "FunctionCreate",
			meta: {
				title: "新增功能",
				fixed: false
			}
		},
		{
			// path: 'modify/:id(\\d+)',
			path: "modify",
			component: () => import("@/views/Functions/function_modify"),
			name: "FunctionModify",
			meta: {
				title: "编辑功能",
				fixed: false
			}
		}
	]
};

export default functionsRouter;
