<template>
    <div style="padding: 0 15px;"
         @click="toggleClick">
        <svg :class="{'is-active':isActive}"
             class="hamburger"
             viewBox="0 0 1024 1024"
             xmlns="http://www.w3.org/2000/svg">
            <path v-if="isMobile"
                  d="M62 62h131.2999998v128.54999971H62z m0 385.7000001h131.2999998v128.54999971H62z m0 385.7000001h131.2999998V962H62zM311.99999961 62h750.00000058v128.54999971H311.99999961z m0 385.7000001h750.00000058v128.54999971H311.99999961z m0 385.7000001h750.00000058V962H311.99999961z m0 0" />
            <path v-else
                  d="M401.06666667 437.33333333h512c4.69333333 0 8.53333333-3.84 8.53333333-8.53333333v-59.73333333c0-4.69333333-3.84-8.53333333-8.53333333-8.53333334H401.06666667c-4.69333333 0-8.53333333 3.84-8.53333334 8.53333334v59.73333333c0 4.69333333 3.84 8.53333333 8.53333334 8.53333333z m-8.53333334 217.6c0 4.69333333 3.84 8.53333333 8.53333334 8.53333334h512c4.69333333 0 8.53333333-3.84 8.53333333-8.53333334v-59.73333333c0-4.69333333-3.84-8.53333333-8.53333333-8.53333333H401.06666667c-4.69333333 0-8.53333333 3.84-8.53333334 8.53333333v59.73333333z m537.6-518.4H93.86666667c-4.69333333 0-8.53333333 3.84-8.53333334 8.53333334v59.73333333c0 4.69333333 3.84 8.53333333 8.53333334 8.53333333h836.26666666c4.69333333 0 8.53333333-3.84 8.53333334-8.53333333v-59.73333333c0-4.69333333-3.84-8.53333333-8.53333334-8.53333334z m0 674.13333334H93.86666667c-4.69333333 0-8.53333333 3.84-8.53333334 8.53333333v59.73333333c0 4.69333333 3.84 8.53333333 8.53333334 8.53333334h836.26666666c4.69333333 0 8.53333333-3.84 8.53333334-8.53333334v-59.73333333c0-4.69333333-3.84-8.53333333-8.53333334-8.53333333zM117.76 650.77333333L284.48 519.46666667a9.42933333 9.42933333 0 0 0 0-14.82666667L117.76 373.22666667c-6.18666667-4.90666667-15.36-0.53333333-15.36 7.36v262.72a9.49333333 9.49333333 0 0 0 15.36 7.46666666z" />
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Hamburger',
        props: {
            isActive: {
                type: Boolean,
                default: false
            },
            isMobile: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            toggleClick () {
                this.$emit('toggleClick')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .hamburger {
    	display: inline-block;
    	vertical-align: middle;
    	height: 20px;
    	width: 20px;
    	&.is-active {
    		transform: rotate(180deg);
    	}
    	transition: transform 0.28s, -webkit-transform 0.28s;
    }
</style>
