<template>
    <div style="padding: 0 15px;">
        <div class="search-input"
             :class="{'focus': isFocus}">
            <el-autocomplete class="inline-input"
                             ref="searchInputer"
                             v-model="queryString"
                             placeholder="请输入关键字"
                             :maxlength="15"
                             :fetch-suggestions="$_QuerySearch"
                             @select="$_HandleSelect"
                             @blur="$_HandelBlur">
                <svg slot="prefix"
                     class="search-icon input-prefix"
                     viewBox="0 0 1024 1024"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M461.74619127 776.15154864A354.89704219 354.89704219 0 0 1 104.67416637 419.15452344 354.89704219 354.89704219 0 0 1 461.74619127 62.00749971a354.89704219 354.89704219 0 0 1 357.07202403 357.0720249 354.89704219 354.89704219 0 0 1-356.9970252 357.07202403z m0-612.14489913a252.59789531 252.59789531 0 0 0-254.99787539 255.0728751c0 142.87380908 112.19906513 255.07287422 254.99787539 255.07287422 142.87380908 0 255.07287422-112.19906513 255.07287422-254.99787539 0-142.87380908-112.19906513-255.14787393-254.99787539-255.14787393z m479.24600625 782.84347646L789.11846314 795.50138779a48.74959336 48.74959336 0 0 1 0-70.64941113c20.24983125-20.24983125 50.62457812-20.24983125 70.87440938 0l151.87373437 151.42373789a48.74959336 48.74959336 0 0 1 0 70.64941113 49.04959131 49.04959131 0 0 1-70.87440937 0z" />
                </svg>
            </el-autocomplete>
        </div>
        <div class="search-handel"
             :class="{'hide': isFocus}">
            <el-tooltip content="快捷搜索"
                        placement="top">
                <svg class="search-icon"
                     viewBox="0 0 1024 1024"
                     xmlns="http://www.w3.org/2000/svg"
                     @click.stop="$_HandelClick">
                    <path d="M461.74619127 776.15154864A354.89704219 354.89704219 0 0 1 104.67416637 419.15452344 354.89704219 354.89704219 0 0 1 461.74619127 62.00749971a354.89704219 354.89704219 0 0 1 357.07202403 357.0720249 354.89704219 354.89704219 0 0 1-356.9970252 357.07202403z m0-612.14489913a252.59789531 252.59789531 0 0 0-254.99787539 255.0728751c0 142.87380908 112.19906513 255.07287422 254.99787539 255.07287422 142.87380908 0 255.07287422-112.19906513 255.07287422-254.99787539 0-142.87380908-112.19906513-255.14787393-254.99787539-255.14787393z m479.24600625 782.84347646L789.11846314 795.50138779a48.74959336 48.74959336 0 0 1 0-70.64941113c20.24983125-20.24983125 50.62457812-20.24983125 70.87440938 0l151.87373437 151.42373789a48.74959336 48.74959336 0 0 1 0 70.64941113 49.04959131 49.04959131 0 0 1-70.87440937 0z" />
                </svg>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Search',
        data () {
            return {
                isFocus: false,
                queryString: ''
            }
        },
        components: {

        },
        methods: {
            $_HandelClick () {
                this.isFocus = !this.isFocus;
                if (this.isFocus) {
                    this.$refs.searchInputer && this.$refs.searchInputer.focus();
                }
            },
            $_HandleSelect (item) {
                console.log(item);
            },
            $_HandelBlur () {
                this.isFocus = false;
            },
            $_QuerySearch (queryString, callback) {
                callback([]);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search-handel {
    	overflow: hidden;
    	float: left;
    	width: 20px;
    	transition: all 0.1s;
    	opacity: 1;
    	&.hide {
    		width: 0;
    		opacity: 0;
    	}
    }
    svg.search-icon {
    	height: 20px;
    	width: 20px;
    	display: inline-block;
    	vertical-align: middle;
    	fill: #666;
    	&.input-prefix {
    		fill: #bbb;
    		height: 16px;
    		width: 16px;
    	}
    }

    .search-input {
    	position: relative;
    	cursor: default;
    	top: 0;
    	right: 0;
    	width: 0;
    	opacity: 0;
    	transition: all 0.3s;
    	float: left;
    	overflow: hidden;
    	&.focus {
    		width: 200px;
    		opacity: 1;
    	}
    	/deep/ .el-input__inner {
    		border-radius: 0;
    		background: transparent;
    		border: none;
    		border-bottom: 2px solid #868686;
    		font-size: 14px;
    	}
    }
</style>
