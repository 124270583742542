<template>
    <div class="app-tabbar">
        <tag-scroll ref="tagScrollPane">
            <tag-item v-for="tag in visitedTags"
                      ref="tag"
                      :key="tag.id"
                      :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
                      :is-focus="isCurrent(tag)"
                      :is-fixed="isFixed(tag)"
                      :title="tag.title"
                      @closeClick="closeTag(tag)"
                      @contextmenu.prevent.native="showContentMenu(tag, $event)" />
        </tag-scroll>
        <ul class="tag-contextmenu"
            ref="refContentMenu"
            v-show="contentMenuVisible"
            :style="{left: contentMenuLeft+'px', top: contentMenuTop+'px'}">
            <li @click="refreshSelectedTag(selectedTag)">刷新</li>
            <li v-if="!(selectedTag.meta&&selectedTag.meta.fixed)"
                @click="closeTag(selectedTag)">关闭</li>
            <li class="split" />
            <li @click="closeOtherTags">关闭其他</li>
            <li @click="closeLeftTags">关闭到左侧</li>
            <li @click="closeRightTags">关闭到右侧</li>
            <li @click="closeAllTags">关闭全部</li>
        </ul>
    </div>
</template>

<script>
    import path from 'path'
    import TagItem from './TagItem'
    import TagScroll from './TagScroll'
    import { appRoutes } from '@/router'

    export default {
        data () {
            return {
                contentMenuVisible: false,
                contentMenuLeft: 0,
                contentMenuTop: 0,
                selectedTag: {},
                affixTags: []
            }
        },
        components: {
            TagScroll,
            TagItem
        },
        watch: {
            contentMenuVisible (visible) {
                if (visible) {
                    document.body.addEventListener('click', this.hideContentMenu)
                } else {
                    document.body.removeEventListener('click', this.hideContentMenu)
                }
            },
            $route () {
                this.createTags()
                this.moveToCurrentTag()
            }
        },
        computed: {
            visitedTags () {
                return this.$store.state.tags.visitedTags
            },
            routes () {
                return appRoutes
            }
        },
        mounted () {
            this.initTags()
            this.createTags()
        },
        methods: {
            isCurrent (tag) {
                return tag.path === this.$route.path
            },
            isFixed (tag) {
                return tag.path === '/dashboard' || (tag.meta && tag.meta.fixed);
            },
            filterAffixTags (routes, basePath = '/') {
                let tags = []
                routes.forEach(route => {
                    if (route.meta && route.meta.affix) {
                        const tagPath = path.resolve(basePath, route.path)
                        tags.push({
                            fullPath: tagPath,
                            path: tagPath,
                            name: route.name,
                            meta: { ...route.meta }
                        })
                    }
                    if (route.children) {
                        const tempTags = this.filterAffixTags(route.children, route.path)
                        if (tempTags.length >= 1) {
                            tags = [...tags, ...tempTags]
                        }
                    }
                })
                return tags
            },
            initTags () {
                const affixTags = this.affixTags = this.filterAffixTags(this.routes)
                for (const tag of affixTags) {
                    if (tag.name) {
                        this.$store.dispatch('tags/addVisitedTag', tag)
                    }
                }
            },
            createTags () {
                const { name } = this.$route
                if (name) {
                    this.$store.dispatch('tags/addTag', this.$route)
                }
                return false
            },
            closeTag (tag) {
                this.$store.dispatch('tags/delTag', tag).then(({ visitedTags }) => {
                    if (this.isCurrent(tag)) {
                        this.moveToLastTag(visitedTags, tag)
                    }
                })
            },
            moveToCurrentTag () {
                const tags = this.$refs.tag;
                this.$nextTick(() => {
                    for (const tag of tags) {
                        if (tag.to.path === this.$route.path) {
                            this.$refs.tagScrollPane.moveToTarget(tag)
                            if (tag.to.fullPath !== this.$route.fullPath) {
                                this.$store.dispatch('tags/updateVisitedTag', this.$route)
                            }
                            break
                        }
                    }
                })
            },
            moveToLastTag (tags, tag) {
                const latestTag = tags.slice(-1)[0]
                if (latestTag) {
                    if (latestTag.path === this.$route.path)
                        return;
                    this.$router.push(latestTag)
                } else {
                    if (tag.name === 'Dashboard') {
                        this.$router.replace({ path: '/redirect' + tag.fullPath })
                    } else {
                        this.$router.push('/')
                    }
                }
            },
            showContentMenu (tag, e) {
                let bodyWidth = document.body.offsetWidth,
                    bodyHeight = document.body.offsetHeight,
                    x = e.clientX,
                    y = e.clientY;
                this.contentMenuVisible = true;
                this.selectedTag = tag;
                setTimeout(() => {
                    let elWidth = this.$refs.refContentMenu.offsetWidth,
                        elHeight = this.$refs.refContentMenu.offsetHeight,
                        maxLeft = bodyWidth - elWidth,
                        maxTop = bodyHeight - elHeight;
                    this.contentMenuLeft = Math.min(x, maxLeft);
                    this.contentMenuTop = Math.min(y, maxTop);
                }, 1);
            },
            hideContentMenu () {
                this.contentMenuVisible = false
            },
            refreshSelectedTag (tag) {
                this.$store.dispatch('tags/delCachedTag', tag).then(() => {
                    const { fullPath } = tag
                    this.$nextTick(() => {
                        this.$router.replace({
                            path: '/redirect' + fullPath
                        })
                    })
                })
            },
            closeLeftTags () {
                if (this.selectedTag.path != this.$route.path)
                    this.$router.push(this.selectedTag)
                this.$store.dispatch('tags/delLeftTags', this.selectedTag).then(() => {
                    this.moveToCurrentTag()
                })
            },
            closeRightTags () {
                if (this.selectedTag.path != this.$route.path)
                    this.$router.push(this.selectedTag)
                this.$store.dispatch('tags/delRightTags', this.selectedTag).then(() => {
                    this.moveToCurrentTag()
                })
            },
            closeOtherTags () {
                if (this.selectedTag.path != this.$route.path)
                    this.$router.push(this.selectedTag)
                this.$store.dispatch('tags/delOthersTags', this.selectedTag).then(() => {
                    this.moveToCurrentTag()
                })
            },
            closeAllTags (tag) {
                this.$store.dispatch('tags/delAllTags').then(({ visitedTags }) => {
                    if (this.affixTags.some(t => t.path === tag.path)) {
                        return
                    }
                    this.moveToLastTag(visitedTags, tag)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-tabbar {
    	height: 36px;
    	background-color: #fafafa;
    	border-bottom: 1px solid #eee;
    	box-sizing: border-box;
    }
    .tag-contextmenu {
    	list-style: none;
    	margin: 0;
    	position: fixed;
    	display: block;
    	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    	border-radius: 4px;
    	background-color: #fff;
    	padding: 2px;
    	z-index: 9999999;
    	cursor: default;
    	-moz-user-select: none;
    	-webkit-user-select: none;
    	-ms-user-select: none;
    	-khtml-user-select: none;
    	user-select: none;
    	overflow: hidden;
    	min-width: 120px;

    	> li {
    		line-height: 36px;
    		padding: 0 15px;
    		font-size: 13px;
    		&:hover {
    			background-color: #f2f2f2;
    		}
    	}
    }
</style>

