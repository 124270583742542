<template>
	<div id="app"
	     class="app">
		<router-view/>
		<div id="globalDialog"></div>
	</div>
</template>
<script>
	export default {
		name: 'App'
	}
</script>
