<template>
    <div ref="floatPanel"
         class="float-panel"
         :class="{'is-open': isOpened,'is-left':this.position==='left'}">
        <div class="float-panel-mask"
             v-if="isModal"
             :style="maskStyle"
             @click="$_modalClick"></div>
        <div class="float-panel-handle"
             v-if="showHandle"
             :style="handleStyle"
             @click="$_toggle">
            <svg class="float-panel-icon"
                 viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M860.75 945.125l45-39.375-360-433.12499999-45 39.37499999zM523.25 945.125L568.25 905.75l-360-433.12499999-45 39.37499999z" />
                <path d="M860.75 78.875l45 39.375-360 433.12499999-45-39.37499999zM523.25 78.875L568.25 118.25 208.25 551.37499999l-45-39.37499999z" />
            </svg>
        </div>
        <div class="float-panel-container float-panel-animation"
             :style="panelStyle">
            <div class="float-panel-close"
                 v-if="showClose"
                 @click="close">
                <svg class="float-panel-icon"
                     viewBox="0 0 1024 1024"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M924.41112627 182.66025763L841.33974237 99.58887373 512 428.92861609 182.66025763 99.58887373 99.58887373 182.66025763 428.92861609 512 99.58887373 841.33974237 182.66025763 924.41112627 512 595.07138391 841.33974237 924.41112627 924.41112627 841.33974237 595.07138391 512z" />
                </svg>
            </div>
            <el-container class="float-panel-body">
                <el-main>
                    <slot />
                </el-main>
                <el-footer ref="buttons"
                           v-if="showFooter"
                           height="40px"
                           class="float-panel-footer">
                    <slot name="buttons" />
                </el-footer>
            </el-container>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FloatPanel",
        props: {
            position: {
                type: String,
                default: 'right'
            },
            isModal: {
                type: Boolean,
                default: true
            },
            width: {
                type: Number,
                default: 280
            },
            showClose: {
                type: Boolean,
                default: true
            },
            showHandle: {
                type: Boolean,
                default: true
            },
            closeOnClickModal: {
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                isOpened: false,
                showFooter: true
            }
        },
        computed: {
            panelStyle () {
                return {
                    'width': this.width + 'px',
                    'left': this.position === 'left' ? '0' : '',
                    'right': this.position !== 'left' ? '0' : ''
                }
            },
            maskStyle () {
                return {
                    'left': this.position === 'left' ? '0' : '',
                    'right': this.position !== 'left' ? '0' : ''
                }
            },
            handleStyle () {
                return {
                    'left': this.position === 'left' ? (this.isOpened ? this.width + 'px' : 0) : '',
                    'right': this.position !== 'left' ? (this.isOpened ? this.width + 'px' : 0) : ''
                }
            }
        },
        methods: {
            $_insertToBody () {
                const elx = this.$refs.floatPanel
                const body = document.querySelector('body')
                body.insertBefore(elx, body.firstChild)
            },
            $_toggle () {
                this.isOpened = !this.isOpened;
            },
            $_modalClick () {
                this.closeOnClickModal === true && this.close();
            },
            open () {
                this.isOpened = true;
                this.showFooter = this.$refs.buttons && this.$refs.buttons.$children && this.$refs.buttons.$children.length > 0;
            },
            close () {
                this.isOpened = false;
            }
        },
        mounted () {
            this.$_insertToBody()
            if (this.$refs.buttons.$children.length === 0) {
                this.showFooter = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .float-panel-mask {
    	z-index: 10001;
    	position: fixed;
    	height: 100%;
    	background-color: rgba(0, 0, 0, 0.4);
    	transition: all 0.3s;
    }
    .float-panel-container {
    	box-shadow: 0 0 8px rgba(0, 21, 41, 0.08);
    	z-index: 10002;
    	position: fixed;
    	top: 0;
    	height: 100%;
    	background: #fff;
    	box-sizing: border-box;
    	.float-panel-body {
    		height: 100%;
    	}
    	.float-panel-footer {
    		text-align: right;
    	}
    }
    .float-panel-icon {
    	height: 16px;
    	width: 16px;
    	fill: #999999;
    }
    .float-panel-handle {
    	cursor: pointer;
    	position: fixed;
    	z-index: 10002;
    	top: 50%;
    	height: 36px;
    	box-sizing: border-box;
    	text-align: right;
    	width: 16px;
    	margin-top: -18px;
    	padding: 10px 0;
    	overflow: hidden;
    	background: rgba(0, 0, 0, 0.6);
    	border-radius: 6px 0 0 6px;
    	.float-panel-icon {
    		transform: rotate(0);
    		fill: #ffffff;
    		height: 14px;
    		width: 14px;
    	}
    }
    .float-panel-close {
    	cursor: pointer;
    	position: absolute;
    	height: 16px;
    	top: 4px;
    	right: 4px;
    	padding: 2px;
    	&:hover {
    		background-color: #cc3333;
    		.float-panel-icon {
    			fill: #fafafa;
    		}
    	}
    }
    .float-panel {
    	z-index: 10000;
    	.float-panel-mask {
    		width: 0;
    	}
    	.float-panel-container {
    		display: none;
    	}
    	&.is-open {
    		.float-panel-mask {
    			width: 100vw;
    		}
    		.float-panel-container {
    			transform: translate(0);
    			display: block;
    		}
    		.float-panel-handle {
    			.float-panel-icon {
    				transform: rotate(180deg);
    			}
    		}
    	}
    	&.is-left {
    		.float-panel-handle {
    			transform: rotate(180deg);
    		}
    	}
    }
</style>

