
module.exports = {
	title: 'WeiShop Admin',
	logoPath: require('@/assets/images/app-logo.svg'),
	company: 'SUNGMON.COM',
	beginYear: 2019,

	// 是否显示设置工具栏
	showSettings: true,
	// 是否请用标签页
	showTabbar: true,
	// 是否固定页头
	fixedHeader: false,
	// 是否显示导航栏logo
	sidebarLogo: false,
	// 日志类型
	errorLog: 'production',
	// 白名单路由
	whiteList: ['/login', '/auth-redirect']
}
