import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

const service = axios.create({
	baseURL: process.env.API_BASE || process.env.VUE_APP_API_BASE,
	timeout: 10000 //10s
});

service.interceptors.request.use(
	config => {
		config.headers["Content-Type"] = "application/json";
		if (store.getters.token) {
			config.headers["token"] = getToken();
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	response => {
		const res = response.data;
		if (res.code !== 0) {
			if (res.code === 1001 || res.code === 1002) {
				MessageBox.alert("您尚未登录或登录超时，请重新登录。", "提示", {
					confirmButtonText: "确定",
					type: "warning"
				}).then(() => {
					store.dispatch("account/resetToken").then(() => {
						location.reload();
					});
				});
			} else {
				Message({
					message: res.msg || "Error",
					type: "error",
					duration: 5 * 1000
				});
			}
			return Promise.reject(new Error(res.msg || "Error"));
		} else {
			return res.data || {};
		}
	},
	error => {
		Message({
			message: error.message,
			type: "error",
			duration: 5 * 1000
		});
		return Promise.reject(error);
	}
);

function apiRequest(method, url, params) {
	return service({
		method: method,
		url: url,
		data: method === "POST" || method === "PUT" ? params : null,
		params: method === "GET" || method === "DELETE" ? params : null
	});
}

export default {
	get: function(url, params) {
		return apiRequest("GET", url, params);
	},
	post: function(url, params) {
		return apiRequest("POST", url, params);
	},
	put: function(url, params) {
		return apiRequest("PUT", url, params);
	},
	delete: function(url, params) {
		return apiRequest("DELETE", url, params);
	}
};
