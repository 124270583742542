<template>
    <div class="tab-item"
         :class="itemClass">
        <div class="tab-item-bg"></div>
        <div class="tab-item-inner">
            <router-link tag="span"
                         :to="to"
                         :title="title">
                <svg class="tab-item-icon focus"
                     viewBox="0 0 1024 1024"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M512 512m-192 0a192 192 0 1 0 384 0 192 192 0 1 0-384 0Z" />
                </svg>
                <span class="title">{{title}}</span>
            </router-link>
            <svg class="tab-item-icon close"
                 v-if="!isFixed"
                 viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg"
                 @click.prevent.stop="closeClick">
                <path d="M924.41112627 182.66025763L841.33974237 99.58887373 512 428.92861609 182.66025763 99.58887373 99.58887373 182.66025763 428.92861609 512 99.58887373 841.33974237 182.66025763 924.41112627 512 595.07138391 841.33974237 924.41112627 924.41112627 841.33974237 595.07138391 512z" />
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TagItem',
        props: {
            to: {
                type: Object,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            isFocus: {
                type: Boolean,
                default: false
            },
            isFixed: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            itemClass () {
                return {
                    'is-focus': this.isFocus,
                    'is-fixed': this.isFixed,
                }
            }
        },
        methods: {
            closeClick () {
                this.$emit('closeClick')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tab-item {
    	display: inline-block;
    	box-sizing: border-box;
    	height: 36px;
    	line-height: 36px;
    	border-top: none;
    	border-left: none;
    	border-right: none;
    	border-bottom: 1px solid #eee;
    	font-size: 14px;
    	vertical-align: middle;
    	.tab-item-inner {
    		cursor: default;
    		padding: 0 15px 0 10px;
    		display: inline-block;
    		span.title {
    			color: #999;
    			float: left;
    			display: block;
    			max-width: 84px;
    			height: 36px;
    			overflow: hidden;
    			text-overflow: ellipsis;
    			white-space: nowrap;
    		}
    	}

    	.tab-item-icon {
    		float: left;
    		box-sizing: border-box;
    		margin: 9px 0;
    		height: 18px;
    		width: 18px;
    		vertical-align: middle;
    		&.focus {
    			fill: #dd9933;
    		}
    		&.close {
    			fill: #999999;
    			cursor: pointer;
    			margin: 11px 0 11px 4px;
    			height: 14px;
    			width: 14px;
    			padding: 2px;
    			border-radius: 50%;
    			background-color: transparent;
    			&:hover {
    				background-color: #cc000099;
    				fill: #fff;
    			}
    		}
    	}

    	&.is-focus {
    		background-color: #fff;
    		border-left: 1px solid #eee;
    		border-right: 1px solid #eee;
    		border-bottom: 1px solid #fff;
    		.tab-item-icon.focus {
    			fill: #29cc76;
    		}
    		.tab-item-inner {
    			> span {
    				color: #333;
    				font-weight: 600;
    			}
    		}
    		&:hover {
    			background-color: #fff;
    		}
    	}
    	&:hover {
    		background-color: #f5f5f5;
    	}
    }
</style>
