<template>
    <div class="app-menuitem-wrapper">
        <template v-if="item.isLeaf">
            <app-link :to="resolvePath(item.router)">
                <el-menu-item :index="item.id">
                    <item :icon="item.icon"
                          :title="item.name" />
                </el-menu-item>
            </app-link>
        </template>
        <el-submenu v-else
                    ref="subMenu"
                    :index="item.id"
                    popper-append-to-body>
            <template slot="title">
                <item :icon="item.icon"
                      :title="item.name" />
            </template>
            <sidebar-item v-for="child in item.children"
                          :key="child.id"
                          :item="child"
                          :base-path="resolvePath(child.router)" />
        </el-submenu>
    </div>
</template>

<script>
    import path from 'path'
    import {
        isExternal
    } from '@/utils/utils.validate.js'
    import Item from './Item'
    import AppLink from './Link'
    import FixiOSBug from './FixiOSBug'

    export default {
        name: 'SidebarItem',
        components: {
            Item,
            AppLink
        },
        mixins: [FixiOSBug],
        props: {
            item: {
                type: Object,
                required: true
            },
            basePath: {
                type: String,
                default: ''
            }
        },
        data () {
            return {}
        },
        methods: {
            resolvePath (routePath) {
                if (isExternal(routePath)) {
                    return routePath
                }
                if (isExternal(this.basePath)) {
                    return this.basePath
                }
                return path.resolve(this.basePath, routePath)
            }
        }
    }
</script>
