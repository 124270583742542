<template>
    <div class="app-workbench">
        <transition name="fade-transform"
                    mode="out-in">
            <keep-alive :include="cachedTags"
                        max="20">
                <router-view :key="key" />
            </keep-alive>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Workbench",
        computed: {
            cachedTags () {
                return this.$store.state.tags.cachedTags
            },
            key () {
                return this.$route.path
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-workbench {
    	height: 100%;
    	padding: 10px;
    	overflow-x: auto;
    }
</style>

