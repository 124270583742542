<template>
    <div class="app-navbar">
        <hamburger class="navbar-item"
                   :is-active="sidebar.opened"
                   :is-mobile="device === 'mobile'"
                   @toggleClick="toggleSideBar" />
        <avatar class="navbar-item right" />
        <full-screen v-if="device !== 'mobile'"
                     class="navbar-item right" />
        <message class="navbar-item right" />
        <theme class="navbar-item right" />
        <search v-if="device !== 'mobile'"
                class="navbar-item right" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Hamburger from './Hamburger'
    import FullScreen from './FullScreen'
    import Avatar from './Avatar'
    import Message from './Message'
    import Theme from './Theme'
    import Search from './Search'

    export default {
        components: {
            Hamburger,
            FullScreen,
            Avatar,
            Message,
            Theme,
            Search
        },
        computed: {
            ...mapGetters([
                'sidebar',
                'avatar',
                'device'
            ]),
            isDesktop () {
                return this.$store.getters.device !== "mobile";
            }
        },
        methods: {
            toggleSideBar () {
                this.$store.dispatch('app/toggleSideBar')
            }
        }

    }
</script>

<style lang="scss" scoped>
    .app-navbar {
    	z-index: 2;
    	height: 50px;
    	overflow: hidden;
    	position: relative;
    	background: #fff;
    	-webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    	.navbar-item {
    		cursor: pointer;
    		line-height: 46px;
    		height: 100%;
    		-webkit-transition: background 0.3s;
    		transition: background 0.3s;
    		-webkit-tap-highlight-color: transparent;
    		float: left;
    		&:hover {
    			background: rgba(0, 0, 0, 0.025);
    		}
    		&.left {
    			float: left;
    		}
    		&.right {
    			float: right;
    		}
    	}
    }
</style>
