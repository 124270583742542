<template>
    <div style="padding: 0 15px;"
         @click="toggleClick">
        <el-tooltip :content="isFullscreen?'退出全屏':'全屏模式'"
                    placement="top">
            <svg class="fullscreen"
                 viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg">
                <path v-if="isFullscreen"
                      d="M900.575 99.50000029c13.76250029 0 24.93749971 11.17500029 24.93749971 24.93749971v775.27500029a24.975 24.975 0 0 1-24.975 24.93749971h-349.83749971v-49.87500029h324.9V149.37499971H150.3125V512h-49.87500029V124.4375c0-13.76250029 11.1375-24.93749971 24.93750058-24.93749971h775.19999971zM400.69999971 624.5v299.99999971h-299.99999971v-299.99999971h299.99999971z m-49.87499942 49.87500029h-200.25v200.25h200.25v-200.25zM766.51250029 220.92499971l35.36249942 35.17500058-267.3 268.46249942h202.46250058v49.87500029h-262.50000029a24.975 24.975 0 0 1-24.67500029-21.2625L449.60000029 549.50000029V287h49.87499942v202.08750029l267.03750058-268.16250058z" />
                <path v-else
                      d="M900.575 99.50000029c13.76250029 0 24.93749971 11.17500029 24.93749971 24.93749971v775.27500029a24.975 24.975 0 0 1-24.975 24.93749971h-349.83749971v-49.87500029h324.9V149.37499971H150.3125V512h-49.87500029V124.4375c0-13.76250029 11.1375-24.93749971 24.93750058-24.93749971h775.19999971zM400.69999971 624.5v299.99999971h-299.99999971v-299.99999971h299.99999971z m-49.87499942 49.87500029h-200.25v200.25h200.25v-200.25z m161.2125-449.81250029h262.49999942c12.52500029 0 22.87500029 9.225 24.6375 21.2625l0.26250029 3.67499971v262.50000029h-49.87500029V309.91249971l-267.03749971 268.16250058-35.36250029-35.17500058L714.5 274.43750029h-202.46249971v-49.87500029h262.49999942z" />
            </svg>
        </el-tooltip>
    </div>
</template>

<script>
    import screenfull from 'screenfull'

    export default {
        name: 'Screenfull',
        data () {
            return {
                isFullscreen: false
            }
        },
        mounted () {
            this.init()
        },
        beforeDestroy () {
            this.destroy()
        },
        methods: {
            toggleClick () {
                if (!screenfull.enabled) {
                    this.$message({
                        message: '您的浏览器不支持全屏。',
                        type: 'warning'
                    })
                    return false
                }
                screenfull.toggle()
            },
            change () {
                this.isFullscreen = screenfull.isFullscreen
            },
            init () {
                if (screenfull.enabled) {
                    screenfull.on('change', this.change)
                }
            },
            destroy () {
                if (screenfull.enabled) {
                    screenfull.off('change', this.change)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fullscreen {
    	height: 20px;
    	width: 20px;
    	display: inline-block;
    	vertical-align: middle;
    }
</style>
