<template>
    <div class="sidebar">
        <logo />
        <el-scrollbar>
            <el-menu :default-active="activeMenu"
                     :collapse="isCollapse"
                     :background-color="variables.menuBg"
                     :text-color="variables.menuText"
                     :unique-opened="false"
                     :active-text-color="variables.menuActiveText"
                     :collapse-transition="false"
                     mode="vertical"
                     class="app-menu">
                <sidebar-item v-for="route in menudata"
                              :key="route.id"
                              :item="route"
                              :base-path="route.router" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Logo from './Logo'
    import SidebarItem from './SidebarItem'
    import variables from '@/styles/variables.scss'

    export default {
        components: {
            Logo,
            SidebarItem
        },
        computed: {
            ...mapGetters([
                'sidebar'
            ]),
            activeMenu () {
                const route = this.$route
                const { meta, path } = route
                // if set path, the sidebar will highlight the path you set
                // if (meta.activeMenu) {
                //     return meta.activeMenu
                // }
                return path
            },
            variables () {
                return variables
            },
            isCollapse () {
                return !this.sidebar.opened
            }
        },
        data () {
            return {
                menudata: [
                    {
                        "id": "m1",
                        "name": "首页",
                        "router": "/dashboard",
                        "iconType": "icon",
                        "icon": "el-icon-s-platform",
                        "children": [],
                        "isLeaf": true
                    },
                    {
                        "id": "m2",
                        "name": "系统设置",
                        "router": "/setting",
                        "iconType": "icon",
                        "icon": "el-icon-setting",
                        "children": [
                            {
                                "id": "m2-1",
                                "name": "系统用户管理",
                                "router": "/users/list",
                                "iconType": "icon",
                                "icon": "el-icon-user",
                                "children": [],
                                "isLeaf": true
                            },
                            {
                                "id": "m2-2",
                                "name": "功能管理",
                                "router": "/functions",
                                "iconType": "icon",
                                "icon": "el-icon-menu",
                                "children": [],
                                "isLeaf": true
                            }
                        ],
                        "isLeaf": false
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/variables.scss";

    .sidebar {
    	.sidebar-mask {
    		background: #000;
    		opacity: 0.3;
    		top: 0;
    		height: 100%;
    		position: absolute;
    		z-index: 999;
    	}

    	/deep/ .el-scrollbar {
    		height: calc(100% - 50px);

    		> .el-scrollbar__wrap {
    			overflow-x: hidden;
    		}
    	}
    }
</style>
