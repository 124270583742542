import Vue from "vue";
import ElementUI from "element-ui";
import NProgress from "nprogress";
import Cookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { titleFormatter, isWhiteList } from "@/utils/utils.pager.js";
import { getToken } from "@/utils/auth";

import "./registerServiceWorker";
import "@/styles/index.scss";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Cookies);
NProgress.configure({ showSpinner: false });

// 全局前置守卫
router.beforeEach((to, from, next) => {
	NProgress.start();
	if (to.matched && to.matched.length > 0) {
		document.title = titleFormatter(to.meta.title);
		const token = getToken();
		if (token) {
			if (to.path === "/login") {
				next({ path: "/" });
			} else {
				next();
			}
			NProgress.done();
		} else {
			if (isWhiteList(to.path)) {
				next();
			} else {
				next(`/login?redirect=${to.path}`);
				NProgress.done();
			}
		}
	} else {
		Notification.error({
			title: "提示",
			message: "抱歉，你访问的功能无效！",
			position: "top-left",
			duration: 1500
		});
		NProgress.done();
	}
});
router.afterEach(() => {
	NProgress.done();
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
