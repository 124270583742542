import Cookies from 'vue-cookies'

const state = {
    name: 'WeiShop Admin',
    logo: 'https://weishop.api.sungmon.com/images/app-logo.png',
    sidebar: {
        opened: Cookies.get('app-sidebarStatus')
            ? !!+Cookies.get('app-sidebarStatus')
            : true,
        withoutAnimation: true
    },
    device: 'desktop',
    size: Cookies.get('app-size') || 'medium',
    showLogo: true
}

const mutations = {
    TOGGLE_SIDEBAR: state => {
        state.sidebar.opened = !state.sidebar.opened
        state.sidebar.withoutAnimation = false
        if (state.sidebar.opened) {
            Cookies.set('app-sidebarStatus', 1)
        } else {
            Cookies.set('app-sidebarStatus', 0)
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('app-sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    SET_SIZE: (state, size) => {
        state.size = size
        Cookies.set('app-size', size)
    },
    SET_NAME: (state, name) => {
        state.name = name
        Cookies.set('app-name', name)
    },
    SET_LOGO: (state, logo) => {
        state.logo = logo
        Cookies.set('app-logo', logo)
    }
}

const actions = {
    toggleSideBar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({ commit }, { withoutAnimation }) {
        commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({ commit }, device) {
        commit('TOGGLE_DEVICE', device)
    },
    setSize({ commit }, size) {
        commit('SET_SIZE', size)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
