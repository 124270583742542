import request from '@/utils/request'
import { getToken } from '@/utils/auth'

export function guest() {
    return request({ url: '/api/token/guest', method: 'post' })
}

export function verifyToken() {
    let token = getToken()
    return request({
        url: '/api/systems/user/validate',
        method: 'post',
        params: { token: token }
    })
}

export function login(loginInfo) {
    return request.post('/api/systems/user/login', loginInfo)
}

export function logout(token) {
    return request.post('/api/systems/user/exit', { token })
}

export function powers() {
    return request({
        url: '/api/systems/user/powers',
        method: 'post'
    })
}
