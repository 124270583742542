<template>
    <div class="app-logo"
         :class="{'collapse':isCollapse}">
        <transition name="appLogoFade">
            <router-link v-if="isCollapse"
                         to="/">
                <img v-if="logo"
                     :src="logo"
                     class="logo" />
                <h1 v-else
                    class="title">{{title}}</h1>
            </router-link>
            <router-link v-else
                         to="/">
                <img v-if="logo"
                     :src="logo"
                     class="logo" />
                <h1 class="title">{{title}}</h1>
            </router-link>
        </transition>
    </div>
</template>

<script>
    import defaultSettings from '@/settings'

    export default {
        data () {
            return {
                title: defaultSettings.title,
                logo: defaultSettings.logoPath
            }
        },
        computed: {
            isCollapse () {
                return !this.$store.getters.sidebar.opened;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-logo {
    	position: relative;
    	width: 100%;
    	height: 50px;
    	line-height: 50px;
    	background: #2b2f3a;
    	text-align: center;
    	overflow: hidden;
    	-webkit-box-shadow: 0 1px 4px rgba(10, 10, 10, 0.651);
    	box-shadow: 0 1px 4px rgba(10, 10, 10, 0.651);
    	& .logo {
    		width: 36px;
    		height: 36px;
    		vertical-align: middle;
    		margin-right: 12px;
    	}
    	& .title {
    		display: inline-block;
    		margin: 0;
    		color: #fff;
    		font-weight: 500;
    		line-height: 50px;
    		font-size: 18px;
    		font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    		vertical-align: middle;
    	}
    	&.collapse {
    		& .logo {
    			margin-right: 0;
    		}
    	}
    }
    .appLogoFade-enter-active {
    	transition: opacity 1.5s;
    }
    .appLogoFade-enter,
    .appLogoFade-leave-to {
    	opacity: 0;
    }
</style>

</style>
