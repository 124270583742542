<template>
    <div style="padding: 0 15px; position: relative;">
        <el-badge class="message-badge"
                  v-if="count>0"
                  :value="count"
                  :max="99"></el-badge>
        <el-tooltip content="消息中心"
                    placement="top">
            <svg class="message"
                 viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M934.96137383 933.5927041c0 14.62017128-11.83776856 26.47596533-26.43476396 26.47596533H115.47339013c-14.5995706 0-26.43476396-11.85579404-26.43476396-26.47596532V377.61158779h105.74034346V90.4060083c0-14.62145888 11.83519336-26.47467773 26.43476396-26.47467773h409.74463565l0.26394873 0.10557949a12.69399112 12.69399112 0 0 1 8.53776826 3.66695244l185.69356172 185.97682441a12.85107276 12.85107276 0 0 1 3.6888416 8.67038643l0.07854082 0.14549327v115.11502118H934.96137383v555.98111631zM115.47339013 443.7995706v489.7931335h769.57982842L156.76909912 443.7995706H115.47339013zM644.17510742 108.68669492v140.57124521h140.35879776L644.17510742 108.68669492zM617.74034346 275.73390547V90.4060083H221.21373359v364.93519306L512 650.9025749 802.78626641 455.34763935V275.73390547H617.74034346z m290.78626641 168.06566513h-41.2931338c-110.52618047 74.34334776-221.05236094 148.68283271-331.5862661 223.0133045l372.8793999 250.76266172V443.7995706z m-198.26394874 17.26094444H313.73733887c-7.3017167 0-13.21802578-5.92532227-13.21802579-13.23733887s5.91630908-13.23733886 13.21802578-13.23733886h396.52532227c7.3030043 0 13.21802578 5.92532227 13.21802579 13.23733886s-5.91502148 13.23733886-13.21802578 13.23733887z m0-79.42532168H313.73733887c-7.3017167 0-13.21802578-5.92532227-13.21802579-13.23733886s5.91630908-13.23862646 13.21802578-13.23862736h396.52532227c7.3030043 0 13.21802578 5.92660987 13.21802579 13.23862735s-5.91502148 13.23733886-13.21802578 13.23733887z" />
            </svg>
        </el-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'Message',
        data () {
            return {
                count: 0
            }
        },
        components: {

        },
        methods: {

        }
    }
</script>


<style lang="scss" scoped>
    .message {
    	height: 20px;
    	width: 20px;
    	display: inline-block;
    	vertical-align: middle;
    }
    .message-badge {
    	line-height: 10px;
    	position: absolute;
    	top: 3px;
    	right: 0;
    }
</style>
