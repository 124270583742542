export function getToken() {
    return localStorage.getItem('X-TOKEN')
}
export function getUserCode() {
    return localStorage.getItem('USER_CODE')
}
export function getUserName() {
    return sessionStorage.getItem('USER_NAME')
}
export function getUserAvatar() {
    return sessionStorage.getItem('USER_AVATAR')
}
export function getUserGender() {
    return sessionStorage.getItem('USER_GENDER')
}
export function getLoginTime() {
    return sessionStorage.getItem('USER_LOGIN_TIME')
}
export function getLastLoginTime() {
    return sessionStorage.getItem('USER_LAST_LOGIN_TIME')
}
export function getSaveCode() {
    return localStorage.getItem('USER_SAVE_CODE')
}

export function removeToken() {
    return localStorage.removeItem('X-TOKEN')
}
