const getters = {
    sidebar: state => state.app.sidebar,
    device: state => state.app.device,
    token: state => state.account.token,
    avatar: state => state.account.avatar,
    code: state => state.account.code,
    name: state => state.account.name,
    visitedViews: state => state.tagViews.visitedViews,
    cachedViews: state => state.tagViews.cachedViews
}
export default getters
