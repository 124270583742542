<template>
    <div style="padding: 0 15px;">
        <el-tooltip content="切换主题"
                    placement="top">
            <svg class="theme"
                 viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M205.52 690.32a30 30 0 0 0 12.93-40.41 308.16 308.16 0 0 1-34.56-142.62c0-176.28 148.05-319.68 330-319.68 18.57 0 37.17 1.5 55.29 4.44a30 30 0 0 0 9.66-59.22 403.65 403.65 0 0 0-64.95-5.25c-215.04 0-390 170.34-390 379.68 0 59.79 13.86 117 41.25 170.1a29.94 29.94 0 0 0 40.38 12.96zM825.5 278.96a30 30 0 0 0-47.46 36.75 310.38 310.38 0 0 1 65.82 191.61c0 57.45-7.77 94.56-28.32 133.62a351.9 351.9 0 0 1-8.46-1.65c-28.32-5.82-75.81-15.51-129.39 32.16-52.71 46.86-43.83 96.81-39.06 123.66 0.48 2.52 0.99 5.52 1.38 8.13-38.22 17.22-73.32 23.73-126.15 23.73a30 30 0 1 0 0 60c65.16 0 109.8-9.24 159.21-32.94 34.11-16.35 27.96-50.91 24.66-69.45-4.2-23.49-7.8-43.77 19.8-68.31 31.08-27.66 53.64-23.1 77.58-18.18 18 3.63 51.48 10.5 69.33-21.45 32.73-58.59 39.42-110.07 39.42-169.35a369.81 369.81 0 0 0-78.36-228.33zM486.38 729.56c0.63-0.15 1.29-0.09 1.95-0.27a60.3 60.3 0 0 0 37.29-29.79l273.75-515.61a60.06 60.06 0 0 0-52.98-88.14c-17.88 0-35.46 7.95-47.22 22.98L337.28 579.98a59.88 59.88 0 0 0-8.25 59.88c-0.96-0.03-1.77-0.15-2.73-0.15-38.55 0-69.21 17.52-86.34 49.35-26.4 49.02-15.33 88.56-4.89 112.08-9.54 9.36-28.59 24.24-47.07 36.45a29.97 29.97 0 0 0 0.6 50.49c2.79 1.77 69.33 42.84 139.44 42.84 35.07 0 65.43-10.05 90.18-29.82 75.72-60.63 71.58-145.26 68.16-171.54z m260.01-573.81l-273.75 515.64L384.5 617 746.39 155.75zM380.75 854.18c-14.04 11.25-31.29 16.71-52.71 16.71-23.04 0-46.38-6.39-65.46-13.59 40.89-34.32 40.5-54 32.49-69.96l-1.65-3.18c-7.53-14.67-17.85-34.74-0.66-66.69 4.11-7.62 12.06-17.76 33.51-17.76 6.75 0 12.51 1.05 15.39 1.71l86.13 45.42c1.2 23.07-2.4 71.64-47.04 107.34z" />
                <path d="M286.07 436.07m-45.93 0a45.93 45.93 0 1 0 91.86 0 45.93 45.93 0 1 0-91.86 0Z" />
                <path d="M357.32 302.93m-29.07 0a29.07 29.07 0 1 0 58.14 0 29.07 29.07 0 1 0-58.14 0Z" />
            </svg>
        </el-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'Theme',
        data () {
            return {

            }
        },
        components: {

        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    .theme {
    	height: 20px;
    	width: 20px;
    	display: inline-block;
    	vertical-align: middle;
    	fill: #666;
    }
</style>
