<template>
    <div class="app-wrapper"
         :class="classObj">
        <div v-if="showMask"
             class="sidebar-mask"
             @click="handleClickOutside" />
        <sidebar class="app-sidebar-container" />
        <div class="app-main-container">
            <navbar />
            <tagbar v-if="showTabbar" />
            <workbench />
        </div>
        <!-- <float-ball/> -->
    </div>
</template>

<script>
    import ResizeHandler from './mixin/ResizeHandler'
    import { mapState } from 'vuex'
    import { Workbench, Navbar, Sidebar, Tagbar } from './components'
    import FloatBall from '@/components/FloatBall'

    export default {
        data () {
            return {

            }
        },
        mixins: [ResizeHandler],
        components: {
            Workbench,
            Navbar,
            Sidebar,
            Tagbar,
            FloatBall
        },
        computed: {
            ...mapState({
                sidebar: state => state.app.sidebar,
                device: state => state.app.device,
                showSettings: state => state.settings.showSettings,
                showTabbar: state => state.settings.showTabbar,
                fixedHeader: state => state.settings.fixedHeader
            }),
            classObj () {
                return {
                    hideSidebar: !this.sidebar.opened,
                    openSidebar: this.sidebar.opened,
                    withoutAnimation: this.sidebar.withoutAnimation,
                    mobile: this.device === 'mobile'
                }
            },
            showMask () {
                return this.device === "mobile" && this.sidebar.opened;
            }
        },
        methods: {
            test () {
                console.log('a')
            },
            handleClickOutside () {
                this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/mixin.scss";
    @import "@/styles/variables.scss";

    .app-wrapper {
    	@include clearfix;
    	position: relative;
    	height: 100%;
    	width: 100%;
    	overflow: hidden;
    	background-color: #eee;

    	&.mobile {
    		&.openSidebar {
    			position: fixed;
    			top: 0;
    		}
    		.sidebar-mask {
    			background: #000;
    			opacity: 0.3;
    			top: 0;
    			height: 100%;
    			width: 100%;
    			position: absolute;
    			z-index: 999;
    		}
    	}
    }
</style>
