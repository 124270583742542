import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";

Vue.use(Router);

import functionsRouter from "./modules/functions";

export const appRoutes = [
	{
		path: "/login",
		name: "Login",
		component: () => import("@/views/Login/index"),
		meta: {
			title: "登录"
		}
	},
	{
		path: "/redirect",
		component: Layout,
		hidden: true,
		children: [
			{
				path: "/redirect/:path*",
				component: () => import("@/views/Redirect/index")
			}
		]
	},
	{
		path: "/",
		name: "Default",
		redirect: "/dashboard",
		component: Layout,
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				component: () => import("@/views/Dashboard/index"),
				meta: {
					title: "仪表盘",
					icon: "dashboard",
					affix: true,
					fixed: true
				}
			}
		]
	},
	functionsRouter
];

const createRouter = () =>
	new Router({
		//mode: 'history',
		scrollBehavior: () => ({ y: 0 }),
		routes: appRoutes
	});

export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}

const router = createRouter();

export default router;
